.c_pager{
  display: flex;
  justify-content: space-between;
  justify-content: center;
  align-items: center;

  margin: 0px 0 50px;
  width: 100%;

  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 16, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 16, $l: 50, $w: 700);

  // 以下はページャに自動的に割り付けられるクラス

  & .page-numbers{
    padding: 10px;
    text-decoration: none;

    font-size: 16px;
    &.prev{
      flex-shrink: 0;
    }
    &.next{
      flex-shrink: 0;
    }
    &:not(.prev):not(.next){
      border-radius: 0;
      // $size: 40px;
      // width: $size;
      // height: $size;

      line-height: 1.2;
      margin: 0 16px;
      @include v_mq(SP){
        display: none;
      }
    }

    display: inline-block;

    // border-bottom: 2px solid transparent;
    &.current{
      background: trasparent;
      color: $clr_black_00;
      // border-bottom: 2px solid $clr_green_01;
      position: relative;
      &:before{
        content: '';
        background: $clr_green_01;
        height: 2px;
        width: 8px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  & a{
    color: inherit;
    &.page-numbers{
      background: trasparent;
      color: $clr_black_00;
      &:hover{
        color: $clr_green_01;
      }
      @mixin prev_next {
        background: none!important;
        color: #ccc;

        vertical-align: middle;
      }
      &.prev{
        @include prev_next;
        &:hover{
          transform: translateX(-10px);
        }
      }

      &.next{
        @include prev_next;
        &:hover{
          transform: translateX(10px);
        }
      }
    }
  }
}
.c_pager_single{
  margin: 0px 0 50px;
  justify-content: space-between;

  justify-content: center;
  @include v_mq(SP3){
    position: relative;
    padding: 56px 0 0;
  }
}
.c_pager_archive{
  max-width: 400px;
  margin: 0px auto 50px;
}
.c_pager__wrap{
  margin: 0;

  @mixin m_pager__wrap {
    width: 171px;
    // width: auto;
    @include v_mq(SP3){
      width: 40px;
      width: auto;
    }
  }
  &:nth-child(1){
    @include m_pager__wrap;
  }
  &:nth-child(2){
    @include v_mq(SP3){
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 0px;
      display: flex;
      justify-content: center;
    }
  }
  &:nth-child(3){
    @include m_pager__wrap;
    // width: auto;
  }
}
.c_pager__arw{
  @include v_mq(SP3){
    width: 47px;
  }
}
.c_pager__text{
  margin: 0 0px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  @include v_font_pc($f: 16, $h: 32, $l: 80, $w: 700);
  @include v_font_sp($f: 16, $h: 32, $l: 80, $w: 700);
  transition: .3s;
  &:hover{
    color: $clr_green_01;
  }
  @include v_mq(SP){
    // display: none;
  }
}
.c_pager__back{
  margin: 0 14px 8px 0;
  @include v_mq(SP3){
    width: 19px;
    margin: 0 14px 4px 0;
  }
}
.c_pager__img{
  max-width: 100%;
}
.c_pager__img_0{
  display: block;
  @include v_mq(SP){
    width: 220px;
  }
}
.c_pager__txt01{
  display: none;
  @include v_mq(SP){
    display: block;
    margin: 0 32px;

    @include v_font_pc($f: 14, $h: 16, $l: 50, $w: bold);
    @include v_font_sp($f: 14, $h: 16, $l: 50, $w: bold);
  }
}
.c_pager__txt02{
  margin: 0 20px;

}
.c_pager__dummyArw{
  // display: none;
  // background: red;
  display: block;
  // opacity: 0;
  width: 53px;
  height: 53px;
}
