.c_guten{
  @mixin m_guten_01 {
    color: $clr_gray_80;
    @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
    @include v_font_sp($f: 12, $h: 28, $l: 50, $w: 300);
    margin: 0 0 63px;
    @include v_mq(SP){
      margin: 0 0 48px;
    }
  }
  word-break: break-all; // 英文の時に折り返しが効くように
  margin: 0 auto 50px;

  & h2{
    margin: 0 0 33px;
    @include v_font_pc($f: 22, $h: 36, $l: 50, $w: 600);
    @include v_font_sp($f: 22, $h: 36, $l: 50, $w: 600);
    @include v_mq(SP){
      margin: 0 0 32px;
    }
  }
  & h3{
    margin: 0 0 16px;
    @include v_font_pc($f: 18, $h: 28, $l: 50, $w: 300);
    @include v_font_sp($f: 18, $h: 32, $l: 50, $w: 300);
    @include v_mq(SP){
      margin: 0 0 16px;
    }
  }
  & h4{
    margin: 0 0 30px;
    @include v_font_pc($f: 16, $h: 32, $l: 50, $w: 300);
    @include v_font_sp($f: 16, $h: 28, $l: 50, $w: 300);
  }
  & p{
    margin: 0 0 32px;
    @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
    @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
    @include v_mq(SP){
      margin: 0 0 48px;
    }
  }
  & strong{
    font-weight: bold;
  }
  & a{
    text-decoration: underline;
    word-break: break-all;
  }
  @mixin m_guten_list {
    list-style-type: none;
    font-size: 16px;
    margin: 0 0 20px;
    @include v_mq(SP){
      font-size: 16px;
      margin: 0 0 20px;
    }
  }
  & ul{
    @include m_guten_list;

    & li{
      padding-left: 13px;
      text-indent: -11px;
      margin: 0 0 8px;
      &:before{
        content: '・';
        width: 1em;
        display: inline-block;
        text-align: center;
      }
      &.blocks-gallery-item{
        &:before{
          display: none;
        }
      }
    }
  }
  & ol{
    @include m_guten_list;
    counter-reset: counter_ul;
    & li{
      padding-left: 22px;
      text-indent: -20px;
      margin: 0 0 8px;
      &:before{
        counter-increment: counter_ul;
        content: counter(counter_ul) '. ';
      }
    }
  }
  & .wp-block-media-text{
    display: block;
    overflow: hidden;

    margin: 0 0 50px;

    @include v_mq(SP){
      display: flex;
      flex-direction: column-reverse;
    }
    &.has-media-on-the-right{
      & .wp-block-media-text__media{
        float: right;
        margin-left: 20px;
        @include v_mq(SP){
          margin-left: 0;
        }
      }
    }
    &:not(.has-media-on-the-right){
      & .wp-block-media-text__media{
        float: left;
        margin-right: 20px;
        @include v_mq(SP){
          margin-right: 0;
        }
      }
    }
    & .wp-block-media-text__media{
      margin: 0 0 20px;
      & img{
        height: 100%;
      }
    }
  }
  & .wp-block-media-text__media{
    max-width: 585px;
    @include v_mq(SP){
      max-width: inherit;
      align-self: center !important;;
    }
  }
  & .wp-block-media-text__content{
    padding: 0 !important;
    @include v_mq(SP){
      margin: 0 0 20px;
    }
  }

  & figcaption{
    @include m_guten_01;
  }
  & > .wp-block-image{
    &.size-large{
      margin: 0 0 48px;
      @include v_mq(SP){
        margin: 0 0 32px;
      }
      & img{
        width: 100%;
      }
    }

  }
  & .wp-block-image{
    overflow: hidden;// 画像を左寄せにした場合にかかるfloatの対応

    & img{
      height: auto;
      margin: 0 0 16px;
      @include v_mq(SP){
        margin: 0 0 16px;
      }
    }
  }
  & > .wp-block-columns{
    // margin: 0;
    & > .wp-block-column{
      @include v_mq(SP){
        flex-basis: 100%!important;
        &:nth-child(2n){
          margin-left: 0;
          margin-right: 0;
        }
      }
      & > .wp-block-image{
        margin: 0;
      }
    }
  }
  & .wp-block-columns{
    margin: 0 0 50px;
    @include v_mq(SP){
      margin: 0 0 20px;
    }
  }
  & .wp-block-separator{
    border-bottom: none;
    margin: 0 0 50px;
    @include v_mq(SP){
      margin: 0 0 20px;
    }
  }
  & .wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
  & .wp-block-embed-youtube{
    margin: 0 0 16px;
    @include v_mq(SP){
      margin: 0 -36px 16px;
    }
    & + p{
      // youtube直後の段落はキャプションとして表示する想定
      // @include m_guten_01;
    }
    & figcaption{
      @include v_mq(SP){
        margin-right: 36px;
        margin-left: 36px;
      }
    }
  }
  & hr{
    border-color: $clr_gray_C0;
    margin: 48px 0 32px;
    @include v_mq(SP){
      margin: 48px 0 32px;
    }
  }
  & blockquote{
    background: $clr_white_03;
    padding: 40px;

    position: relative;
    margin: 75px 0 19px;

    @include v_mq(SP){
      margin: 55px 0 16px;
      padding: 59px 40px 13px;
      padding: 30px 24px;
    }
    &:before{
      content: '';
      background-image: url('../images/quote.svg');
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      right: 22px;
      top: -27px;;
      width: 72px;
      height: 59px;
      @include v_mq(SP){
        right: 18px;
        top: -24px;;
        width: 64px;
        height: 52px;
      }
    }
    & p{
      font-size: 12px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    & + p{
      margin-bottom: 48px;
      @include v_mq(SP){
        margin-bottom: 32px;
      }
    }
    & cite{
      font-size: 12px;
    }
  }
}
