@charset "utf-8";

/* 表示非表示 ユーティリティ */
.h_pc {
  @include v_mq(SP){
    display: none !important;
  }
}
.h_sp {
  @include v_mq(PC){
    display: none !important;
  }
}

/* margin ユーティリティ */
$prefixes: "mt","mr","mb","ml","mx","my";
@each $prefix in $prefixes{
  @for $i from 0 through 80 {
    .h_#{$prefix}#{$i*5} {
      @if $prefix == "mt" {
        margin-top: #{$i*5}px;
      } @else if $prefix == "mr" {
        margin-right: #{$i*5}px;
      } @else if $prefix == "mb" {
        margin-bottom: #{$i*5}px;
      } @else if $prefix == "ml" {
        margin-left: #{$i*5}px;
      } @else if $prefix == "mx" {
        margin-left: #{$i*5}px;
        margin-right: #{$i*5}px;
      } @else if $prefix == "my" {
        margin-top: #{$i*5}px;
        margin-bottom: #{$i*5}px;
      }
    }
    .h_#{$prefix}#{$i*5}sp {
      @include v_mq(SP){
        @if $prefix == "mt" {
          margin-top: #{$i*5}px !important;
        } @else if $prefix == "mr" {
          margin-right: #{$i*5}px !important;
        } @else if $prefix == "mb" {
          margin-bottom: #{$i*5}px !important;
        } @else if $prefix == "ml" {
          margin-left: #{$i*5}px !important;
        } @else if $prefix == "mx" {
          margin-left: #{$i*5}px !important;
          margin-right: #{$i*5}px !important;
        } @else if $prefix == "my" {
          margin-top: #{$i*5}px !important;
          margin-bottom: #{$i*5}px !important;
        }
      }
    }
  }
}

/* text-align ユーティリティ */
.h_tal{
  text-align: left!important;
}
.h_tac{
  text-align: center!important;
}
.h_tar{
  text-align: right!important;
}

/* font-weight: bold ユーティリティ */
.h_bold{
  font-weight: bold!important;
}
.h_clrGreen01{
  color: $clr_green_01;
}
.h_clrRed01{
  color: $clr_red_01;
}
.h_clrWhite01{
  color: white;
}
.h_blend01{
  mix-blend-mode: overlay;
}
.h_blend02{
  mix-blend-mode: difference;
  color: #fff;
}
.h_sticky01{
  position: sticky;
  top: 20px;
  z-index: 100;
  @include v_mq(SP){
    top: 200px;
  }
}
.h_sticky01_spOff{
  @include v_mq(SP){
    position: static;
  }
}
.h_bdr01{
  border-top: 1px solid $clr_gray_C0;
}
.h_arw01{
  position: relative;
  padding: 0 0 0 11px;
  &:before{
    content: '';
    background-image: url('../images/arw01.svg');
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: 10px;
    // bottom: 0px;
    // margin: auto;
    width: 8px;
    height: 8px;
    @include v_mq(SP){
      width: 8px;
      height: 8px;
    }
  }
}
.h_parallax01{
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover;
}
.h_imgBlend01{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: .3s;
  z-index: 1;
  opacity: 0;
  mix-blend-mode: color;

  &:hover{
    background-color: #525665;
    opacity: 1;
  }
  // &:before{
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: #525665;
  //   mix-blend-mode: color;
  //   opacity: 1;
  //   z-index: 1;
  // }
}
.h_txtHov01{
  position: relative;
  &:after{
    position: absolute;
    bottom: -4px;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background: white;
    transform: scale(0, 1);
    transform-origin: center top;
    transition: transform .3s;
  }
  &:hover{
    &:after{
      transform: scale(1, 1);
    }
  }
}
.h_txtHov01_black{
  &:after{
    background: $clr_black_00;
  }
}
.h_txtHov01_01{
  &:after{
    bottom: -2px;
  }
}
.h_txtDotSep01{
  display: inline-flex;
  align-items: center;
  &:not(:last-child){
    &:after{
      content: "";
      margin: 0 12px 0 16px;
      background: $clr_black_00;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      display: inline-block;
    }
  }
}
