.l_comp01{
  // margin: 0px 0 65px;
  margin: -11vw 0 65px;
  @include v_mq(SP){
    margin: 0 0 0;
  }
}
.c_comp01{
  &:not(:last-child){
    margin: 0 0 145px;
    @include v_mq(SP){
      margin: 0 0 48px;
    }
  }
  &:last-child{
    padding-bottom: 250px;
    @include v_mq(SP){
      padding-bottom: 200px;
    }
  }
}
.c_comp01__01{
  margin: 0;
}
.c_comp01__02{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: calc(702 / 1206) * 100%;

  margin: 0 0 40px;
  @include v_mq(SP){
    $val: 16px;
    margin: 0 -1 * $val 24px;
    width: calc(100% + $val * 2);
    padding-bottom: calc(225 / 335) * 100%;
  }
}
.c_comp01__img{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.c_comp01__hl{
  font-family: agehasprings;
  @include v_font_vw_pc($f: 56, $h: 48, $l: 0, $w: normal);
  @include v_font_vw_sp($f: 24, $h: 32, $l: 0, $w: normal);
  text-align: right;
  @include v_mq(SP){
    text-align: left;
  }
}
.c_comp01__ttl{
  @include v_font_pc($f: 18, $h: 36, $l: 50, $w: bold);
  @include v_font_sp($f: 18, $h: 36, $l: 50, $w: bold);
}
.c_comp01__txt{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: normal);

  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.c_comp02{
  display: inline-flex;
  align-items: center;
  transition: .3s;
  &:hover{
    & .c_comp02__txt{
      color: $clr_green_01;
    }
    & .c_comp02__img_def{
      opacity: 0;
    }
    & .c_comp02__img_hover{
      opacity: 1;
    }
  }
}
.c_comp02__01{
  position: relative;
}
.c_comp02__txt{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: normal);
  color: $clr_black_00;

  transition: inherit;
}
.c_comp02__txt_01{
  @include v_font_pc($f: 20, $h: 23, $l: 0, $w: normal);
  @include v_font_sp($f: 20, $h: 23, $l: 0, $w: normal);
  color: white;
}
.c_comp02__txt_02{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: normal);
}
.c_comp02__icon{
  vertical-align: baseline;
}
.c_comp02__img{
  margin: 0 10px 0 0;
  width: 48px;
  transition: .3s;
}
.c_comp02__img_def{
  opacity: 1;
}
.c_comp02__img_hover{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.c_comp02__img_01{
  margin: 0 10px 0 0;
  @include v_img_pc($w: 48);
}
.c_comp03{
  margin: 0;
}
.c_comp03__tag{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: normal);
  color: $clr_gray_80;

  margin: 0 12px 0 0;

  &:not(:last-child){
    &:after{
      content: ', ';
      position: absolute;
      margin: 0 0 0 3px;
      @include v_mq(SP){
        margin: 0 0 0 1px;
      }
    }
  }

  position: relative;
  &:before{
    position: absolute;
    bottom: -4px;
    left: 0;
    content: '';
    width: 100%;
    height: 1px;
    background: $clr_gray_80;
    transform: scale(0, 1);
    transform-origin: center top;
    transition: transform .3s;
  }
  &:hover{
    &:before{
      transform: scale(1, 1);
    }
  }
}
.c_comp03__tag_AA{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: normal);
}
.c_comp03__tag_01{
  color: $clr_white_02;
  &:before{
    background: $clr_white_02;
  }
  &.is-clr{
    color: black;
    &:before{
      background: black;
    }
  }

}
.c_comp04{
  display: inline-flex;
  align-items: center;
  &:hover{
    color: $clr_green_01;
    & .c_comp04__img{
      transform: translateX(10px);
    }
  }
}
.c_comp04__img{
  margin: 0 20px 0 0;
  @include v_img_pc($w: 78);
}
.c_comp04__txt{
  @include v_font_pc($f: 22, $h: 32, $l: 80, $w: normal);
  @include v_font_sp($f: 22, $h: 32, $l: 80, $w: normal);
}
.c_comp05{
  margin: 0;
}
.c_comp06{
  // transition: transform 1.5s;
  padding-bottom: calc(384 / 1224) * 100%;
  position: relative;
  display: block;

  &:not(:last-child){
    margin: 0 0 19px;
    @include v_mq(SP){
      margin: 0 0 32px;
    }
  }
  @include v_mq(SP){
    padding: 0 0 calc(384 / 301) * 100%;
  }

  & .c_comp02{
    & .c_comp02__txt{
      color: $clr_green_01;
      color: white;
    }
    & .c_comp02__img_def{
      opacity: 0;
    }
    & .c_comp02__img_hover{
      opacity: 1;
    }
    @include v_mq(SP){
      display: none;
    }
  }
  &:not(:hover){
    & .c_comp02{
      opacity: 0;
      @include v_mq(SP){
        opacity: 1;
      }
    }
  }
  &:hover{
    & .c_comp02{
      opacity: 1;
    }
  }
}
.c_comp06__01{
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding: 37px 42px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;


  @include v_mq(SP){
    padding: 20px 30px;
  }
}
.c_comp06__txt01{
  font-family: 'aktiv-grotesk';
  color: white;
  @include v_font_pc($f: 48, $h: 61, $l: 50, $w: normal);
  @include v_font_sp($f: 48, $h: 61, $l: 50, $w: 300);
}
.c_comp07{
  display: none; // 初期状態
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $clr_black_00;
}
.c_comp07__01{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include v_mq(SP){
    $margin: 100px;
    height: calc(100% - $margin * 2);
    margin: $margin 0 0;
    justify-content: flex-start;
    padding: 50px 0;
    overflow-y: auto;
  }
}
.c_comp07__02{
  text-align: center;
  &:not(:last-child){
    margin: 0 0 64px;
    @include v_mq(SP){
      margin: 0 0 32px;
    }
  }
}
.c_comp07__txt01{
  font-family: agehasprings;
  color: white;
  @include v_font_pc($f: 48, $h: 40, $l: 0, $w: normal);
  @include v_font_sp($f: 40, $h: 40, $l: 0, $w: normal);
  &:hover{
    color: $clr_green_01;
  }
}
.c_comp08{
  display: flex;
  justify-content: flex-end;
  & i{
    color: white;
    font-size: 22px;
  }
}
.c_comp08_01{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 40px 40px 0;
  @include v_mq(SP){
    justify-content: center;
    padding: 0 0 42px;
  }
}
.c_comp08__01{
  &:not(:last-child){
    margin: 0 28px 0 0;
  }
}
.c_comp08__link01{
  &:hover{
    opacity: .5;
  }
}
.c_comp09{
  position: absolute;
  z-index: 10001;
  // $val: 29px;
  $val: 0px;
  top: $val;
  right: $val;
}
.c_comp09__close{
  cursor: pointer;
  transition: 1.0s;
  @include v_mq(SP){
    width: 80px;
  }
  &:hover{
    @include v_mq(PC){
      transform: rotate(360deg);
    }
  }
}
.c_comp10{
  display: inline-flex;
  align-items: center;

  cursor: pointer;
  transition: .3s;
  &:hover{
    color: $clr_green_01;
    & .c_comp10__btn{
      & circle{
        fill: white;
      }
    }
  }
}
.c_comp10__btn{
  margin: 0 16px 0 0;
}
.c_comp11{
  @include v_mq(SP){
    overflow-x: scroll;
    width: 100%;
    overflow-y: hidden;
    padding: 0 0 10px;

    margin: 0 0 75px;
  }
}
.c_comp11__01{
  display: flex;
}
.c_comp11__item{
  @include v_mq(SP){
    white-space: nowrap;
  }
  &:not(:last-child){
    margin: 0 40px 0 0;
  }
}
.c_comp11__link{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 16, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 16, $l: 50, $w: 700);

  border-bottom: 2px solid transparent;
  padding: 0 0 5px;
  &.is-current{
    border-bottom-color: $clr_black_00;
  }
}
.c_comp12{
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover{
    & .c_comp12__txt{
      color: $clr_green_01;
    }
    & .c_comp12__img{
      & circle{
        fill: $clr_black_00;
      }
      & path{
        fill: white;
      }
    }
  }
}
.c_comp12__img{
  width: 40px;
  margin: 0 8px 0 0;
}
.c_comp12__txt{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 17, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 17, $l: 50, $w: 700);
  transition: .3s;
}
.c_comp13{
  display: none; // 初期状態
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;

  color: white;

  padding: 152px 133px 119px;
  overflow-y: auto;
  @include v_mq(SP){
    padding: 158px 36px 71px;
  }
}
.c_comp13__txt01{
  font-family: agehasprings;
  @include v_font_pc($f: 24, $h: 29, $l: 50, $w: 400);
  @include v_font_sp($f: 32, $h: 38, $l: 50, $w: 400);
}
.c_comp14{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_comp14__radio{
  display: none;
  &:checked{
    & + .c_comp14__01{
      color: $clr_green_01;
    }
  }
}
.c_comp14__txt01{

  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 40, $h: 60, $l: 50, $w: 300);
  @include v_font_sp($f: 24, $h: 36, $l: 50, $w: 300);

  cursor: pointer;

  display: flex;
  align-items: flex-start;
  white-space: nowrap;

  &:not(:last-child){
    &:after{
      content: '/';
      margin: -2px 12px 0 12px;
      color: $clr_white_01;
    }
  }
}
.c_comp14__txt02{
  font-family: 'aktiv-grotesk';
  color: $clr_green_01;
  @include v_font_pc($f: 12, $h: 14, $l: 50, $w: 300);
  @include v_font_sp($f: 12, $h: 14, $l: 50, $w: 300);
  margin: 10px 0 0 6px;
  @include v_mq(SP){
    margin: 7px 0 0 6px;
  }
}
.c_comp14__txt03{
  color: inherit;
  @include v_font_pc($f: 40, $h: 60, $l: 50, $w: 300);
  @include v_font_sp($f: 24, $h: 36, $l: 50, $w: 300);
  &:hover{
    // color: $clr_green_01;
  }
}
.c_comp14__01{
  color: $clr_white_01;

  display: flex;
  align-items: flex-start;
}
.c_comp15{
  margin: 0;
  &:not(:last-child){
    margin: 0 0 45px;
  }
}
.c_comp15__01{
  margin: 0;

  display: flex;
  flex-wrap: wrap;
}
.c_comp15__02{
  cursor: pointer;
  margin: 0 0 18px;
  display: block;
}
.c_comp15__txt01{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 17, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 17, $l: 50, $w: 700);
}
.c_comp15__txt02{
  @include v_font_pc($f: 14, $h: 14, $l: 50, $w: 400);
  @include v_font_sp($f: 14, $h: 14, $l: 50, $w: 400);
}
.c_comp16{
  display: flex;
  height: 88px;
  &:hover{
    & .c_comp16__img{
      & path{
        fill: $clr_green_01;
      }
    }
    & .c_comp16__01{
      background: white;
    }
  }
  @include v_mq(SP){
    height: 64px;
  }
}
.c_comp16__01{
  background: $clr_green_01;
  width: 88px;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: .3s;

  border: none;
  @include v_mq(SP){
    width: 64px;
  }
}
.c_comp16__img{
  @include v_mq(SP){
    width: 15px;
  }
}
.c_comp16__input{
  width: 100%;
  border: none;
  background: $clr_gray_28;

  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 24, $h: 64, $l: 50, $w: 300);
  @include v_font_sp($f: 16, $h: 64, $l: 50, $w: 400);

  color: rgba(255,255,255,.5);
  padding: 0 32px;
  &:focus-visible{
    outline: none;
  }
  &:not(:placeholder-shown){
    color: white;
    & + .c_comp16__01{
      background: white;
      border: none;
      cursor: pointer;
      & .c_comp16__img{
        & path{
          fill: $clr_green_01;
        }
      }
    }
  }
  @include v_mq(SP){
    padding: 0 16px;
  }
}
.c_comp17{
  display: flex;
  align-items: center;
  justify-content: center;
  background: $clr_green_01;
  padding: 34px 42px;
  width: 400px;
  position: relative;
  border: none;
  color: $clr_black_00;

  transition: .3s;
  cursor: pointer;
  &:hover{
    background: white;
    color: $clr_green_01;

    & .c_comp17__img{
      & path{
        fill: $clr_green_01;
      }
    }
  }
  @include v_mq(SP){
    padding: 23px 27px;
    width: 100%;
  }
}
.c_comp17__txt01{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 18, $h: 21, $l: 50, $w: bold);
  @include v_font_sp($f: 18, $h: 21, $l: 50, $w: bold);
  color: inherit;

  text-align: center;
  display: block;
}
.c_comp17__img{
  position: absolute;
  left: 42px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.c_comp18{
  display: flex;
  align-items: center;
}
.c_comp18__01{
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;
}
.c_comp18__txt01{
  margin: 0;
  @include v_font_pc($f: 22, $h: 28, $l: 50, $w: 600);
  @include v_font_sp($f: 22, $h: 28, $l: 50, $w: 600);
  &:not(:last-child){
    &:after{
      content: ',';
      margin: 0 10px 0 0;
    }
  }
}
.c_comp18__txt02{
  margin: 0;
  @include v_font_pc($f: 22, $h: 28, $l: 50, $w: 400);
  @include v_font_sp($f: 22, $h: 28, $l: 50, $w: 400);
}
.c_comp19{
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: calc(753 / 1376) * 100%;
  @include v_mq(SP){
    text-align: initial;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: calc(375 / 375) * 100%;

    padding-bottom: 0;
    height: auto;
  }
}
.c_comp19__img01{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;

  transition: .3s;
  @include v_mq(SP){
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1) !important; // js側で設定されるstyleの打ち消し用

    object-fit: contain;
    width: auto;
    height: auto;
    position: static;
  }
}
.l_comp20{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -15px 69px;
  @include v_mq(SP){
    margin: 0 -15px 38px;
  }
}
.c_comp20{
  margin: 0 15px 25px;
  width: calc(calc(100 / 3) * 1% - 30px);

  @include v_mq(SP){
    width: 100%;
    margin: 0 15px 24px;
  }
}
.c_comp20_mov{
  width: 100%;
}
.c_comp20__01{
  margin: 0;
}
.c_comp20__02{
  display: flex;
}
.c_comp20__txt01{
  @include v_font_pc($f: 12, $h: 28, $l: 50, $w: 600);
  @include v_font_sp($f: 12, $h: 28, $l: 50, $w: 600);
}
.c_comp20__txt02{
  @include v_font_pc($f: 12, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 12, $h: 28, $l: 50, $w: 300);
  margin: 0 5px 0 0;
  word-break: break-all;
}
.c_comp20__txt02_01{
  opacity: .5;
}
.c_comp21{
  margin: 0;
  &:hover{
    opacity: .6;
  }
}
.c_comp22{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $clr_gray_C0;
  border-bottom: 1px solid $clr_gray_C0;
  padding: 46px 0;
  @include v_mq(SP2){
    padding: 40px 0;
    flex-direction: column;
  }
}
.c_comp22__01{
  margin: 0;
  @include v_mq(SP2){
    margin: 0 0 41px;
  }
}
.c_comp22__01_noInfo{
  @include v_mq(SP2){
    display: none;
  }
}
.c_comp22__02{
  flex-shrink: 0;
  @include v_mq(SP2){
    display: flex;
    justify-content: flex-end;
  }
}
.c_comp22__03{
  @include v_mq(SP2){
    flex-direction: column;
  }
}
.c_comp22__04{
  margin: 5px 40px 0 0;
  @include v_mq(SP2){
    margin: 0px 0px 25px;
  }
}
.c_comp23{
  margin: 0;
}
.c_comp23__txt01{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 17, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 17, $l: 50, $w: 700);
  &:not(:last-child){
    margin: 0 40px 0 0;
  }
  border-bottom: 2px solid transparent;
  padding: 0 0 6px;
  @mixin m_comp23__txt01 {
    border-bottom-color: $clr_black_00;
  }
  &.is-current{
    @include m_comp23__txt01;
  }
}
.c_comp24{// 管理画面埋め込みクラス
  padding: 0 0 0 80px;
  @include v_mq(SP){
    padding: 0;
  }
}
.c_comp24__txt01{
  @include v_font_pc($f: 24, $h: 48, $l: 50, $w: 300);
  @include v_font_sp($f: 18, $h: 44, $l: 50, $w: 300);
}
.c_comp24__txt02{
  font-family: 'aktiv-grotesk';
  display: block;
  color: $clr_gray_80;

  @include v_font_pc($f: 24, $h: 48, $l: 50, $w: 300);
  @include v_font_sp($f: 18, $h: 36, $l: 50, $w: 300);
}
.c_comp24__txt03{
  display: block;
  @include v_font_pc($f: 22, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 18, $h: 28, $l: 50, $w: 300);
}
.c_comp24__txt04{
  display: block;
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
}
.c_comp24__txt05{
  display: block;
  @include v_font_pc($f: 24, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 24, $h: 28, $l: 50, $w: 300);
}
.c_comp25{
  overflow: hidden;

  display: flex;
  // justify-content: center;
}
.c_comp25__txt01{
  font-family: agehasprings;
  color: $clr_gray_AB;
  @include v_font_vw_pc($f: 200, $h: 200, $l: 0, $w: normal);
  @include v_font_vw_sp($f: 276, $h: 276, $l: 0, $w: normal);

  white-space: nowrap;

  // position: absolute;
  // position: fixed;

  animation-duration: 40s;
  // animation-duration: 1s;
  animation-delay: 0s;
  animation-timing-function: linear;
  animation-direction:normal;
  animation-fill-mode:none;
  animation-iteration-count: infinite;

  position: absolute;

  @include v_mq(SP){
    font-size: 42.6vw;
  }
}
.c_comp25__txt01_toLeft{

  &.is-current{
    animation-name: backStrLeft;

    @keyframes backStrLeft{
      0%{
        transform: translateX(100vw);
      }
      100%{
        transform: translateX(-100%);
      }

    }
  }
}
.c_comp25__txt01_toRight{

  &.is-current{
    animation-name: backStrRight;

    @keyframes backStrRight{
      0%{
        transform: translateX(-100%);
      }
      100%{
        transform: translateX(100vw);
      }
    }
  }
}
.c_comp25__txt01_top{
  top: 0;
}
.c_comp25__txt01_btm{
  bottom: 0;
}
.l_comp26{
  @include v_mq(SP){
    margin: 0 -16px 50px;
  }
}
.c_comp26{
  max-width: 770px;
  width: 100%;
  margin: 0;
  &:nth-child(2n-1){
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    & .c_comp26__02{
      margin-right: -59px;
      width: min-content;
      @include v_mq(SP){
        margin-right: 0px;
        width: auto;
        width: calc(100% - 15px);
      }
    }
  }
  &:nth-child(2n){
    // margin-left: auto;
    max-width: initial;
    & .c_comp26__02{
      max-width: calc(770px + 203px);
      margin-left: auto;
      @include v_mq(SP){
        margin-left: 15px;
      }
    }
    & .c_comp26__03{
      max-width: 770px;
      margin-left: auto;
    }
  }
}
.c_comp26__01{
  width: 100%;
  height: 0;
  padding-bottom: calc(539 / 777) * 100%;
  // margin: 0 0 25px;
  position: relative;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @include v_mq(SP){
    padding-bottom: calc(235 / 335) * 100%;
  }
}
.c_comp26__02{
  margin-bottom: 128px;
  position: relative;
  z-index: 1;
  @include v_mq(SP){
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 32px * 2;
  }
}
.c_comp26__02_01{
  margin-top: -60px;

  @include v_mq(SP){
    margin-top: -30px;
  }
}
.c_comp26__02_02{
  margin-top: -170px;
  @include v_mq(SP){
    margin-top: -30px;
  }
}
.c_comp26__03{
  width: 100%;
  height: 500px;
  height: auto;
}
.c_comp26__img{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.c_comp26__txt01{
  font-family: 'agehasprings';
  color: $clr_green_02;
  // @include v_font_pc($f: 114, $h: 137, $l: 0, $w: 400);
  @include v_font_pc($f: 114, $h: 114, $l: 0, $w: 400);
  @include v_font_sp($f: 64, $h: 64, $l: 0, $w: 400);
  @include v_mq(SP){
    font-size: 12.5vw
  }
}
.c_comp26__txt02{
  color: white;
  @include v_font_pc($f: 16, $h: 32, $l: 50, $w: 300);
  @include v_font_sp($f: 16, $h: 32, $l: 50, $w: 300);
}
.c_comp27{
  margin: 0;
}
.c_comp27__01{
  display: flex;
  border-top: 1px solid $clr_gray_C0;

  padding: 25px 0;
  // &:last-child{
  //   border-bottom: 1px solid $clr_gray_C0;
  // }
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_comp27__02{
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_comp27__03{
  &:first-child{
    width: 200px;
    flex-shrink: 0;
    @include v_mq(SP){
      width: 100%;
      margin: 0 0 8px;
    }
  }
}
.c_comp27__txt01{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
  &:not(:first-child){
    margin: 0 0 0 20px;
    @include v_mq(SP){
      margin: 0;
    }
  }
}

.c_comp28{ // 管理画面埋め込みクラス
  @include v_font_pc($f: 14, $h: 17, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 17, $l: 50, $w: 700);

  position: relative;
  // font-weight: bold;

  $val: 12px;
  padding: 0 0 0 calc($val + 8px);
  margin: 0 0 0 10px;
  @include v_mq(SP){
    display: block;
    margin: 0;
  }
  &:before{
    content: '';
    background-image: url('../images/map.svg');
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;

    top: -1px;
    left: 0;

    width: $val;
    height: 17px;

    bottom: 0;
    margin: auto;
  }
  &:hover{
    color: $clr_green_01;
  }
}
.c_comp29{
  & iframe{
    width: 100%;

    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}
.c_comp29__01{
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 30px;
}
.c_comp29__02{
  margin: 0 0 50px;
  @include v_mq(SP){
    margin: 0 -1 * $g_val_02 40px;
  }
}
.c_comp30{
  margin: 0 0 118px;
  // @include v_mq(SP4){
  @include v_mq(SP){
    margin: 0 -1 * $g_val_02 80px;
  }
}
.c_comp30__01{
  position: relative;
  z-index: 1;

  display: flex;
  @include v_mq(SP5_2){
    align-items: flex-start;
  }
  @include v_mq(SP4){
    flex-direction: column;
  }
  &:not(:last-child){
    margin: 0 0 118px;
  }
}
.c_comp30__02{
  position: relative;
  z-index: 1;

  overflow: hidden;
  @include v_img_vw_pc(572);
  flex-shrink: 0;

  @include v_mq(SP4){
    width: calc(100% - 1 * $g_val_02);
    z-index: 0;
  }
}
$c_comp3_img_rate_sp: calc(389 / 340) * 100%;
.c_comp30__03{
  background: white;
  padding: 83px 132px;
  margin: 84px 0 -38px;

  position: relative;

  @include v_mq(SP5_1){
    padding: 42px 61px;
  }
  @include v_mq(SP5_3){
    padding: 21px 30px;
  }

  &:before{
    content: '';
    background: white;
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    // width: 329px;
    width: 55%;
    @include v_mq(SP4){
      background: transparent;
    }
  }
  @include v_mq(SP4){
    margin: 0px 0 -0px $g_val_02;
    padding: 23px 31px;
    $val: 40px;
    padding-top: calc(1 * $c_comp3_img_rate_sp - $val);
    margin-top: calc(-1 * $c_comp3_img_rate_sp + $val * 2);
    z-index: -1;
  }
}
.c_comp30__txt01{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 44, $h: 56, $l: 50, $w: 300);
  @include v_font_sp($f: 40, $h: 44, $l: 50, $w: 300);

  margin: 0 0 40px;
  @include v_mq(SP){
    margin: 0 0 32px;
  }
}
.c_comp30__txt02{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
  margin: 0 0 40px;
}
.c_comp31{
  margin: 0 0 131px;
}
.c_comp31__01{
  &:not(:last-child){
    margin: 0 0 80px;
  }
}
.l_comp32{
  margin: 0 0 108px;
  @include v_mq(SP){
    margin: 0 0 44px;
  }
}
.c_comp32{
  margin: 0 0 44px;
  padding: 0 0 44px;
  border-bottom: 1px solid $clr_gray_C0;
  @include v_mq(SP){
    margin: 0 0 44px;
    padding: 0 0 44px;
  }
  &:last-child{
    margin-bottom: 0;
  }
}
.c_comp32__01{
  display: flex;
  align-items: flex-start;
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_comp32__txt01{
  font-family: agehasprings;
  @include v_font_pc($f: 22, $h: 26, $l: 0, $w: 400);
  @include v_font_sp($f: 22, $h: 26, $l: 0, $w: 400);
  margin: 5px 85px 0 0;
  @include v_mq(SP){
    margin: 0 0 18px;
  }
}
.c_comp32__txt02{
  @include v_font_pc($f: 24, $h: 36, $l: 50, $w: 400);
  @include v_font_sp($f: 24, $h: 36, $l: 50, $w: 400);
}
