.c_form{
  margin: 0;
  @mixin m_form_01 {
    font-family: 'aktiv-grotesk';
    // color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 1);
    @include v_font_pc($f: 16, $h: 24, $l: 50, $w: normal);
    @include v_font_sp($f: 16, $h: 24, $l: 50, $w: normal);

    background: none;
    border: none;
    width: 100%;
  }
  & .error{
    font-family: 'aktiv-grotesk';
    @include v_font_pc($f: 12, $h: 24, $l: 50, $w: 300);
    @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
    color: $clr_red_02 !important;
    font-size: 12px !important;
    @include v_mq(SP){
      font-size: 14px !important;
    }
  }
  & br{
    display: none;
  }
  & select{
    @include m_form_01;
    color: inherit;
  }
  & input{
    @include m_form_01;
  }
  & textarea{
    @include m_form_01;
  }
  & [type="submit"]{
    font-family: 'aktiv-grotesk';
    @include v_font_pc($f: 14, $h: 24, $l: 50, $w: 400);
    @include v_font_sp($f: 14, $h: 24, $l: 50, $w: 400);
    background: $clr_green_01;
    color: $clr_black_00;
    padding: 18px 10px;
    border: none;

    cursor: pointer;
    width: 100%;

    transition: .3s;
    &:hover{
      background: white;
      color: $clr_green_01;
    }
    &:not([disabled]){
      border: 1px solid transparent;
    }
    &[disabled]{
      border: 1px solid $clr_gray_C0;
      color: white;
      background: $clr_black_00;
      cursor: initial;
      pointer-events: none;

      border-color: transparent;
      background: #303030;
      color: rgba(255,255,255,.5);
    }
  }
}
.c_form__lbl{
  display: flex;
  align-items: flex-start;
  &:not(:last-child){
    margin: 0 0 56px;
    @include v_mq(SP){
      margin: 0 0 50px;
    }
  }
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_form__required{
  color: $clr_red_01;
  margin: 0 5px;
}
.c_form__item{
  width: 200px;
  flex-shrink: 0;

  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 700);
  @include v_mq(SP){
    margin: 0 0 24px;
    width: 100%;
  }
}
.c_form__parts{
  width: 100%;
}
.c_form__01{
  display: block;
  border-bottom: 1px solid $clr_gray_50;
  margin: 0 0 16px;
  padding: 0 0 16px;
  & .error{
    display: none !important;
  }
}
.c_form__parts_select{
  display: block;
  width: 100%;
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  &.is-selected{
    color: white;
  }
  &:before{
    content: '';
    position: absolute;
    $w: 4px;
    border-top: $w solid white;
    border-right: $w solid transparent;
    border-bottom: $w solid transparent;
    border-left: $w solid transparent;
    top: 4px;
    bottom: 0;
    margin: auto;
    height: 0;
    right: 4px;
  }
}
.c_form__parts_btn{
  border: none;
  padding: 0;

  display: flex;
  @include v_mq(SP){
    flex-direction: column-reverse;
  }
}
.c_form__txt01{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
}
.c_form__back{
  margin: 0 35px 0 0;
  width: 169px !important;
  flex-shrink: 0;

  &:not(:hover){
    background: #545454;
    color: white;
  }
  @include v_mq(SP){
    margin: 40px 0 0;
    width: 100% !important;
  }
}
//////////確認画面開始//////////
.mw_wp_form_confirm{
  & .c_form__01{
    border: none;
  }
  & .c_form__parts_select{
    &:before{
      display: none;
    }
  }
}
//////////確認画面終了//////////
.grecaptcha-badge{
  display: none!important;
}
.c_form__item_01{
 width: 100%;
 margin: 30px auto;
 text-align: center;
}
.c_form__item_01 a{
 color: white;
 text-decoration: underline;
}