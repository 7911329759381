@mixin arw_common {
  content: '';
  display: block;
  height: 14px;
  width: 3px;
  background-color: #C31916;
  position: absolute;
  left: 50%;
}

.l_slick01{
  overflow: hidden;
  overflow: initial;
  padding: 0 0 60px;

  @include v_mq(SP){
    padding: 0 0 40px;
    margin: 0 -1 * $g_val_02;
  }
}
[data-js-hov_master_slick01]{
  &:hover{
    & .c_slick01__arw{
      opacity: 1 !important;
    }
  }
}
.c_slick01{
  $val: 67px;
  // $val: 0.86%;
  margin: 0 -1 * $val;
  @include v_mq(SP){
    margin: 0;
  }
  & .slick-list{
    overflow: visible;
    @include v_mq(SP){
      padding: 0 32px !important;
    }
  }
  & .slick-slide{
    margin: 0 $val;
    height: auto;
    @include v_mq(SP){
      margin: 0 10px;
    }
  }
}
.c_slick01__wrap01{
  display: flex;
  justify-content: center;

  flex-direction: column;
  margin: 0 0 20px;
}
.c_slick01__wrap02{
  width: 100%;
  max-width: 500px;
  position: relative;
  &.is_show{
    &:before{
      opacity: 0;
      z-index: 0;
    }
  }
}
.c_slick01__wrap03{
  position: relative;
  height: 0;
  padding-bottom: calc(320 / 571) * 100%;
  margin: 0 0 44px;
  @include v_mq(SP){
    margin: 0 0 16px;
    padding-bottom: calc(153 / 272) * 100%;
  }
}
.c_slick01__img{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  margin: 0 0 10px;
}
.c_slick01__txt01{
  text-align: left;
  direction: ltr;

  @include v_font_pc($f: 18, $h: 36, $l: 50, $w: normal);
  @include v_font_sp($f: 18, $h: 27, $l: 50, $w: normal);
}
.c_slick01__arw{
  // display: none !important;

  position: absolute;
  top: 100%;
  top: 12vw;
  // width: 25px;
  // height: 25px;

  cursor: pointer;
  z-index: 1;

  transition: .3s;
  opacity: 0 !important; // 初期状態
  @include v_mq(SP){
    display: none !important;
  }
}
@mixin m_slick_arw_01 {
  background-image: url('../images/arwSlick.svg');
  width: 64px;
  height: 64px;
  display: block;
}
@mixin m_slick_arw_prev {
  left: 20px;
  left: calc(-1 * calc($g_val_03 / $g_design_size) * 100vw + 100px);
}
.c_slick01__arw_prev{
  @include m_slick_arw_prev;

  &:before{
    // @include arw_common;
    // transform: rotate(45deg);
    // top: 0;
    content: '';
    @include m_slick_arw_01;
    transform: rotate(180deg);
  }
  &:after{
    // @include arw_common;
    // transform: rotate(-45deg);
    // top: 8px;
  }
}
@mixin m_slick_arw_next {
  // right: 20px;
  // right: calc(1 * calc($g_val_03 / $g_design_size) * 100vw + 100px);

  left: calc(100vw - calc($g_val_03 / $g_design_size * 100vw) - 40px);
}
.c_slick01__arw_next{
  @include m_slick_arw_next;

  &:before{
    // @include arw_common;
    // transform: rotate(-45deg);
    // top: 0;
    content: '';
    @include m_slick_arw_01;
  }
  &:after{
    // @include arw_common;
    // transform: rotate(45deg);
    // top: 8px;
  }
}
.c_slick01__dummy{
  position: absolute;
  top: 0;
  z-index: 1;
  // pointer-events: none;
  cursor: pointer;

  width: 50%;
  height: 100%;

  ///////////////////////
  // pointer-events: none;
  opacity: 0 !important;
  // cursor: initial;
  // display: none;
  @include v_mq(SP){
    display: none;
    pointer-events: none
  }
}
.c_slick01__dummy_left{
  left: 0;

  background: red;
  opacity: .2;
}
.c_slick01__dummy_right{
  right: 0;

  background: blue;
  opacity: .2;
}
.c_slick01__date{
  font-family: 'aktiv-grotesk';
  color: $clr_gray_80;
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: normal);
}
////////////
.l_slick02{
  overflow: hidden;
  overflow: initial;
  padding: 0 0 113px;

  @include v_mq(SP){
    padding: 0 0 40px;
    margin: 0 -1 * $g_val_02;
  }
}
[data-js-hov_master_slick02]{
  &:hover{
    & .c_slick02__arw{
      opacity: 1 !important;
    }
  }
}
.c_slick02{
  $val: 66px;
  margin: 0 -1 * $val;
  @include v_mq(SP){
    margin: 0;
  }
  & .slick-track{
    margin-left: 0;
  }
  & .slick-list{
    overflow: visible;
    @include v_mq(SP){
      padding: 0 32px !important;
    }
  }
  & .slick-slide{
    margin: 0 $val;
    height: auto;
    @include v_mq(SP){
      margin: 0 10px;
    }
  }
}
.c_slick02__wrap01{
  display: flex;
  justify-content: center;

  flex-direction: column;
  margin: 0 0 20px;
}
.c_slick02__wrap02{
  width: 100%;
  max-width: 500px;
  position: relative;
  &.is_show{
    &:before{
      opacity: 0;
      z-index: 0;
    }
  }
}
.c_slick02__txt01{
  text-align: left;
  direction: ltr;

  @include v_font_pc($f: 24, $h: 36, $l: 50, $w: 400);
  @include v_font_sp($f: 24, $h: 36, $l: 50, $w: 400);

  margin: 0 0 45px;
  @include v_mq(SP){
    margin: 0 0 45px;
  }
}
.c_slick02__arw{
  // display: none !important;

  position: absolute;
  top: 100%;
  top: 12vw;
  z-index: 1;
  // width: 25px;
  // height: 25px;

  cursor: pointer;

  transition: .3s;
  opacity: 0 !important; // 初期状態
  @include v_mq(SP){
    display: none !important;
  }
}
.c_slick02__arw_prev{
  @include m_slick_arw_prev;

  &:before{
    // @include arw_common;
    // transform: rotate(45deg);
    // top: 0;
    content: '';
    @include m_slick_arw_01;
    transform: rotate(180deg);
  }
  &:after{
    // @include arw_common;
    // transform: rotate(-45deg);
    // top: 8px;
  }
}
.c_slick02__arw_next{
  @include m_slick_arw_next;
  &:before{
    // @include arw_common;
    // transform: rotate(-45deg);
    // top: 0;
    content: '';
    @include m_slick_arw_01;
  }
  &:after{
    // @include arw_common;
    // transform: rotate(45deg);
    // top: 8px;
  }
}
.c_slick02__dummy{
  position: absolute;
  top: 0;
  z-index: 1;
  // pointer-events: none;
  cursor: pointer;

  width: 50%;
  height: 100%;

  ///////////////////////
  // pointer-events: none;
  opacity: 0 !important;
  // cursor: initial;
  // display: none;
  @include v_mq(SP){
    display: none;
    pointer-events: none
  }
}
.c_slick02__dummy_left{
  left: 0;

  background: red;
  opacity: .2;
}
.c_slick02__dummy_right{
  right: 0;

  background: blue;
  opacity: .2;
}
.c_slick02__date{
  font-family: agehasprings;
  display: block;
  color: $clr_black_00;
  @include v_font_pc($f: 22, $h: 26, $l: 0, $w: 400);
  @include v_font_sp($f: 22, $h: 26, $l: 0, $w: 400);
  margin: 0 0 16px;
  @include v_mq(SP){
    margin: 0 0 32px;
  }
}
////////////
