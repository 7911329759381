@charset "UTF-8";
@import "variables";

.c_customCheck{
  & [type="checkbox"]{
    display: none;

    &:checked{
      &+span{
        &:after{
          opacity: 1;
        }
      }
    }
  }

  $clr: #f0af00;

  & span{
    position: relative;
    padding: 0 0 0 25px;
    margin: 0 20px 0 0;

    padding: 0 0 0 22px;
    &:before{
      content: '';
      width: 14px;
      height: 14px;
      position: absolute;
      border: 1px solid $clr;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: white;

      bottom: auto;
      top: 1px;

      border: none;
      background: $clr_gray_5A;
      width: 12px;
      height: 12px;
    }
    &:after{
      content: '';
      position: absolute;
      top: 50%;
      box-sizing: border-box;
      display: block;

      left: 3px;
      width: 16px;
      height: 8px;
      margin-top: -8px;

      border-left: 3px solid $clr;
      border-bottom: 3px solid $clr;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 0;

      top: 12px;

      border: none;
      background: $clr_green_01;
      transform: initial;
      width: 6px;
      height: 6px;
      top: 12px;
    }
  }
}
