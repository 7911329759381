@charset "UTF-8";
@import '../../node_modules/reset.css/reset.css';
// @import '../../node_modules/universal-parallax/dist/universal-parallax.min.css';
@import "variables";

@import "c_guten.scss";
@import "c_search.scss";
@import "c_bread.scss";
@import "c_pager.scss";
@import "c_spMenu.scss";
@import "c_width.scss";
@import "c_form.scss";
///////////
@import "c_txt.scss";
@import "c_video.scss";
@import "c_logo.scss";
@import "c_comp.scss";
@import "c_slick.scss";
@import "c_thumb.scss";
@import "c_header.scss";
@import "c_img.scss";
@import "c_list.scss";
@import "c_scrollAnime.scss";
@import "c_customCheck.scss";
@import "c_movie.scss";
///////////
@import "l.scss";
///////////
///////////
@import "u_arw.scss";
@import "u_parallax.scss";
@import "helpers.scss";

////////////////
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
////////////////
@font-face {
  font-family: "YuGothic M";
  // src: local(Yu Gothic Medium)
  src: local("Yu Gothic Medium");
}

/*//////////////以上はWindows対応//////////////*/
*{
  box-sizing: border-box;
}
html {
	line-height: 1.5;
  font-size: 62.5%;

  font-size: 16px;
  color: #000;
}
@mixin basic_font {
  font-family: 'Noto sans JP', serif;
}
body {
  @include basic_font;
  -webkit-font-smoothing: antialiased; // safariとchromeの文字太さの同期をとる
  -webkit-print-color-adjust: exact; // chrome印刷時に背景画像を表示させる。
  overscroll-behavior: none;
  @media print{
    zoom: 0.68;
  }
  & *{
    font-family: 'aktiv-grotesk', 'ヒラギノ角ゴ ProN', "Hiragino Kaku Gothic ProN", "Helvetica Neue", "Arial", "Hiragino Sans", "Meiryo", sans-serif;
  }
}
/* IE10以上の印刷対応 */
@media print and (-ms-high-contrast: none) {
  /* @pageの指定いらないかも */
  @page {
    size: A4;
    margin: 12.7mm 9.7mm;
  }

  body {
    zoom: 1.8;
    width: 1200px;
    transform: scale(0.5);
    transform-origin: 0 0;
  }
}
button, input, optgroup, select, textarea{
  @include basic_font;
  &:focus-visible{
    outline: none;
  }
}
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}
img{
  max-width: 100%;
  vertical-align: bottom;
}
p{
  margin: 0;
}
a{
  text-decoration: none;
  color: $clr_black_00;
  transition: .3s;
  & img{
    transition: .3s;
  }
}
a:hover{
  text-decoration: none;
}
a[href*="tel:"] {
  pointer-events: none;
  // display: block;//これを指定しないとpointer-events: none;が効かない？
  @include v_mq(SP){
    pointer-events: all;
  }
}
a[href*="javascript:void(0);"] {
  pointer-events: none;
}
nav li::before{
  position: absolute; //reseter.css対策
}
ul{
  list-style-type: none;
  margin: 0;
}
ol{
  margin: 0;
}
.slick-arrow{
  &.slick-disabled{
    opacity: .3;
    pointer-events: none;

    display: none !important;
  }
}
/////////
@font-face {
  /*フォントの名前*/
  font-family: 'agehasprings';
  /*フォントファイルへのパス（複数指定の場合、上から順に読み込まれる）*/
  src: url("../font/agehasprings-Regular.woff") format("woff"),
}
main{
  margin-bottom: 100vh !important; // フッタのせりあがり用.
  &.is-footerNotFixed{
    margin-bottom: 0 !important;
  }
}
svg{
  * {
    transition: .3s;
  }
}
