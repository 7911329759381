// .c_video01{
//   width: 100%;
//   position: absolute;
//   z-index: -1;
// }
.c_video01{
  position: relative;
  &_fullScreen{
    // @include v_mq(PC){
      height: 100vh;
    // }
  }
  &__video{
    background: $clr_black_00; // data-js-luminance-backのための背景色設定（本要素自体にこの背景色が視認されるわけではない。）
    width: 100%;
    // @include v_mq(PC){
      min-height: 800px;
      z-index: -1;
      position: fixed;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;


      min-width: calc(100vh * 812.34 / 456.94);
      height: 100vh;

      min-height: calc(100vw * 456.94 / 812.34);
    // }
    @include v_mq(SP){
      min-width: calc(100vh * 372.3 / 685.8);
      height: 100vh;
// 372.3 685.8
      min-height: calc(100vw * 685.8 / 372.3);
    }
  }
}
.c_video01_notFixed{
  overflow: hidden;
}
.c_video01__video_notFixed{
  position: absolute;
}
.c_video02{
  position: relative;
}
.c_video02__video{
  width: 100%;
}
.c_video02__btn{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;

  cursor: pointer;

  transition: .3s;
  &:not(.is-active){
    opacity: 1;
  }
  &.is-active{
    opacity: 0;
    pointer-events: none;
  }

  & path{
    transition: .3s;
  }

  &:hover{
    & path{
      fill: $clr_green_01;
    }
  }
  @include v_mq(SP){
    $size: 40px;
    width: $size;
    height: $size;
  }
}
.c_video03{
  overflow: hidden;
}
.c_video03__video{
  pointer-events: none;
  width: 100%;
}
