[data-js-scroll-anime]{
  transition: .3s;
}
[data-js-scroll-anime_type01]{
  &:not(.is-scrollAnime){
    opacity: 0;
    transform: translateY(50px);
  }
  &.is-scrollAnime{
    opacity: 1;
    transform: translateY(0px);
  }
}
[data-js-scroll-anime_type02]{
  &:not(.is-scrollAnime){
    opacity: 0;
    transform: translateY(50px);
  }
  &.is-scrollAnime{
    opacity: 1;
    transform: translateY(0px);
  }
  &:nth-child(2n){
    // transition-delay: .2s;
    @include v_mq(SP){
      transition-delay: .0s;
    }
  }
  &[data-js-scroll-anime_type02_01]{
    transition-delay: .0s;
  }
}
[data-js-scroll-anime_type03]{
  &:not(.is-scrollAnime){
    opacity: 0;
  }
  &.is-scrollAnime{
    opacity: 1;
  }
}
[data-js-scroll-anime_type04]{
  &:nth-child(1){
    &:not(.is-scrollAnime){
      opacity: 0;
      // transform: translateY(50px);
    }
    &.is-scrollAnime{
      opacity: 1;
      // transform: translateY(0px);
    }
  }
  &:nth-child(2){
    transition-delay: .2s;
    @include v_mq(SP){
      transition-delay: .0s;
    }
    &:not(.is-scrollAnime){
      opacity: 0;
      transform: translateY(50px);
    }
    &.is-scrollAnime{
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
