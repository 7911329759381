.c_movie{
  position: relative;
  // padding-bottom: (451 / 802) * 100%; // （height / width） * 100%
  padding-bottom: (360 / 640) * 100%; // （height / width） * 100%
  height: 0;
  overflow: hidden;
  & iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
