.c_header{
  margin: 0;
}
.c_header__nav01{
  // margin: calc($g_val_01 / $g_design_size) * 100vw 64px 0 0;
  margin: $g_val_01 * 1px 64px 0 0;
  display: flex;
  justify-content: flex-end;
  @include v_mq(SP){
    display: none;
  }
}
$header_txt_clr_01: white;
$header_txt_clr_02: $clr_black_00;
.c_header__01{
  color: $header_txt_clr_01;
  margin: 0;
  $val01_pc: 80px;
  $val01_sp: 2.3%;
  &.is-clr{
    color: $header_txt_clr_02;
    &:last-child{
      &:before{
        background: $header_txt_clr_02;
      }
    }
  }
  &:not(:last-child){
    margin: 0 $val01_pc 0 0;
    @include v_mq(header_SP){
      margin: 0 $val01_sp 0 0;
    }
  }
  // &:last-child{
  //   position: relative;
  //   padding: 0 0 0 $val01_pc;
  //   @include v_mq(header_SP){
  //     padding: 0 0 0 $val01_sp;
  //   }
  //   &:before{
  //     content: '';
  //     width: 2px;
  //     background: $header_txt_clr_01;
  //     position: absolute;
  //     height: 100%;
  //     left: 0;
  //   }
  // }
}
.c_header__txt01{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 17, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 17, $l: 50, $w: 700);
  color: inherit;

  display: inline-block;
  text-decoration: none;

  &.is-clr{
    &:after{
      background: #333;
    }
  }
}
