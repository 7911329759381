$spMenu_clr_1: #5D5D5D;
.c_spMenu{
  position: relative;
  @include v_mq(PC){
    display: none;
  }

  & ul{
    padding: 0;
    margin: 0;

    font-size: 15px;
    letter-spacing: normal;
    text-align: left;
    color: $spMenu_clr_1;
  }
}
.c_spMenu__btn{
  $border_clr: #222222;
  position: relative;

  width: 25px;
  margin: 0;

  padding: 8px 0;
  cursor: pointer;
  transition: .3s;

  box-sizing: border-box;

  @mixin border_width {
    height: 2px;
  }

  @mixin before_after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    @include border_width;
    right: 0;
    background-color: $border_clr;

    transition: .3s;
    box-sizing: border-box;
  }

  @mixin center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  &:before{
    @include before_after;
    top: 0;
  }

  &:after{
    @include before_after;
    bottom: 0;
  }

  &.is-current{
    @mixin active {
      width: 80%;
      @include center;
    }
    &:before{
      @include active;
      transform: rotate(45deg);
    }

    &:after{
      @include active;
      transform: rotate(-45deg);
    }

    & a{
      width: 0 !important;
    }
  }

  &.is-data-js-header{
    $color_is-data-js-header: white;//ヘッダ透過時の文字色
    &:before{
      background-color: $color_is-data-js-header;
    }

    &:after{
      background-color: $color_is-data-js-header;
    }

    & a{
      background-color: $color_is-data-js-header;
    }
  }

  & a{
    width: 100%;
    @include center;
    @include border_width;
    background-color: $border_clr;
    transition: .4s;
  }
}
.c_spMenu__list01{
  &>li{
    & ul{
      display: none;// 初期状態
    }
    &>ul{
      &>li{
        & .c_spMenu__wrap02{
          background: white;
          &_hasChild{
            background: $spMenu_clr_1;
            & a{
              color: white!important;
              font-weight: normal;
            }

            & .c_spMenu__icon_pm{
              &:before{
                background: white;
              }
              &:after{
                background: white;
              }
            }
          }
        }
        &>ul{
          &>li{
            & .c_spMenu__wrap02{
              background: $spMenu_clr_1;
              & a{
                color: white!important;
                font-weight: normal;
              }

              & .c_spMenu__icon_arw{
                &:before{
                  border-left-color: white;
                }
              }
            }
          }
        }

      }
    }
  }
}
.c_spMenu__wrap01{
  display: none; // 初期状態

  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;

  background: #e4e4e4;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 80vh;
  padding: 0 0 100px;

  /////////////
  position: fixed;
  left: 0;
  width: 100%;
  top: -13px;
  z-index: -1;
  padding: 100px 0 0;
  height: 100vh;
}
.c_spMenu__wrap02{
  border-bottom: 1px solid #ccd2db;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: stretch; // アイコンの区ｒっかぶる領域を広めるための設定。
  padding: 9px 21px 9px 16px;


  &_hasChild{
    &>a{
      // font-weight: bold;
      font-weight: normal;
    }
  }
}
.c_spMenu__wrap03{
  display: flex;
}
.c_spMenu__txt{
  color: $spMenu_clr_1;
  display: block;
  width: 100%;
}
@mixin center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.c_spMenu__icon{
  width: 12px;
  position: relative;

  cursor: pointer;

}
.c_spMenu__icon_arw{
  position: relative;
  @mixin ba {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    background: $spMenu_clr_1;

    right: 0px;

    top: 0;
    bottom: 0;
    margin: auto;
    transition: .3s;
  }
  &:before{
    @include ba;
    transform: rotate(45deg);
    top: -6px;
  }
  &:after{
    @include ba;
    transform: rotate(-45deg);
    top: 6px;
  }
}

.c_spMenu__icon_pm{

  @mixin pm {
    content: '';
    @include center;

    width: 100%;
    background: $spMenu_clr_1;
    height: 1px;
  }
  &:before{
    @include pm;
  }
  &:after{
    @include pm;
    transition: .3s;
    transform: rotate(90deg);
  }

  &.is-current{
    &:after{
      transform: rotate(0deg);
    }
  }
}
