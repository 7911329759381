.c_searchWrap01{
  margin: 50px 0 50px;
}
.c_searchWrap02{
  margin: 0px 0 50px;
}
.c_searchWrap03{
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_searchWrap04{
  display: flex;
  justify-content: space-between;
  margin: 0 0 100px;
  @include v_mq(SP){
    flex-direction: column;
    margin: 0 0 40px;
  }
}
.c_searchTxt01{
  font-size: 22px;
  margin: 0 0 20px;
  font-weight: bold;
  @include v_mq(SP){
    font-size: 18px;
  }
}
.c_searchTxt02{
  font-size: 18px;
  margin: 0 0 50px;
  @include v_mq(SP){
    font-size: 16px;
  }
}
.c_searchComp01{
  margin: 0;
  font-size: 16px;
  @include v_mq(SP){
    font-size: 14px;
    margin: 0 0 30px;
  }
}
.c_searchComp01__current{
  margin: 0;
  &:after{
    content: '|';
    width: 1px;
    margin: -2px 12px 0 6px;
    vertical-align: top;
    display: inline-block;
  }
}
.c_searchComp01__total{
  margin: 0;
}
.c_searchComp02{
  margin: 0;
  padding: 0;
  display: flex;
  @include v_mq(SP){
    justify-content: flex-end;
  }
}
.c_searchComp02__item{
  margin: 0;
  &:not(:last-child){
    margin: 0 5px 0 0;
  }
}
.c_searchComp02__link{
  font-size: 20px;
  padding: 5px 10px;
  border: 1px solid #535353;
  border-radius: 20px;
  display: block;
  width: 160px;
  text-align: center;
  @include v_mq(SP){
    font-size: 12px;
    width: 76px;
  }
}
