.u_parallax{
  overflow: hidden; // 必須

  width: 100%;
  height: 0;
  padding-bottom: calc(539 / 777) * 100%;
  @include v_mq(SP){
    padding-bottom: calc(235 / 335) * 100%;
  }
}
.u_parallax_01{
  padding-bottom: calc(384 / 1224) * 100%;
  @include v_mq(SP){
    padding: 0 0 calc(384 / 301) * 100%;
  }
}
.u_parallax_02{
  // width: 572px;
  // width: 100%;
  height: 0;
  padding-bottom: calc(665 / 572) * 100%;
  @include v_mq(SP4){
    width: 100%;
    padding-bottom: $c_comp3_img_rate_sp;
  }
}
.u_parallax__img{
  transition: 0s;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  // width: 100%;
}
