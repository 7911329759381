.c_list01{
  list-style: disc;
  padding: 0 0 0 20px;
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
}
.c_list01_counter01{
  counter-reset: counter_list01_counter01;

  list-style: none;
  text-indent: -25px;
  padding: 0 0 0 27px;
  & li{
    counter-increment: counter_list01_counter01;
    &:before{
      content: counter(counter_list01_counter01)'. ' ;

    }
  }
}
.c_list02{

  @include v_font_pc($f: 10, $h: 15, $l: 50, $w: 300);
  @include v_font_sp($f: 10, $h: 15, $l: 50, $w: 300);

  text-indent: -22px;
  padding: 0 0 0 22px;
  & > li{
    margin: 0 0 10px;
    &:before{

      content: '※';

    }
  }
}
.c_list02_count{
  counter-reset: counter_list02;
  & > li{
    &:before{
      counter-increment: counter_list02;
      content: '※' counter(counter_list02);
    }
  }
}
.c_list03{
  @include v_font_pc($f: 10, $h: 15, $l: 50, $w: 300);
  @include v_font_sp($f: 10, $h: 15, $l: 50, $w: 300);
  list-style: disc;
  padding: 0 0 0 18px;
}
.c_list04{
  list-style: decimal;
  padding: 0 0 0 22px;
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
}
.c_list05{
  margin: 0;
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_list05__01{
  display: flex;
  flex-direction: column;
  &:not(:last-child){
    margin: 0 128px 0 0;
    @include v_mq(SP){
      margin: 0 0 32px;

      padding: 0 0 32px;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background: $clr_gray_50;
        width: 93px;
        height: 1px;
      }
    }
  }
}
.c_list05__link{
  font-family: 'aktiv-grotesk';
  color: white;
  @include v_font_pc($f: 14, $h: 17, $l: 50, $w: bold);
  @include v_font_sp($f: 14, $h: 17, $l: 50, $w: bold);
  &:not(:last-child){
    margin: 0 0 15px;
  }
  transition: .3s;
  &:hover{
    opacity: .6;
  }
}
.c_list06{
  @include v_font_pc($f: 18, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 18, $h: 28, $l: 50, $w: normal);
  list-style: none;
  padding: 0 0 0 25px;
  & li{
    margin: 0 0 24px;
    position: relative;

    &:before{
      content: '';
      width: 8px;
      height: 8px;
      background: $clr_black_00;
      border-radius: 100%;
      position: absolute;
      top: 10px;
      left: -20px;
    }
  }
}
