.c_bread{
  padding: 10px 0px;
  background: blue;

  & a{
    text-decoration: none;
  }
}

.c_bread__list{

  margin: 0;
  padding: 0 0 0 30px;

  font-size: 16px;
  line-height: 1.8;

  font-weight: 400;

  color:  #333333;
  text-align: justify;

  @include v_mq(SP){
    font-size: 14px;
    text-align: left;
  }

  &>li{
    display: inline;
    vertical-align: top;

    &:not(:last-child):after{
      content: ' / ';
      margin: 0 10px;
      color: white;
    }

    & a{
      &:not(:hover){
        color: white;
      }
    }
  }
}
.c_bread__current{
  color: white;
}
