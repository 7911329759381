.c_txtBasic01{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
}
.c_txt00{
  font-family: agehasprings;
  @include v_font_vw_pc($f: 160, $h: 192, $l: 0, $w: normal);
  // @include v_font_vw_sp($f: 40, $h: 60, $l: 0, $w: normal);
  @include v_font_vw_sp($f: 51.375, $h: 70, $l: 0, $w: normal);
}
.c_txt00_01{
  @include v_font_vw_sp($f: 140, $h: 140, $l: 0, $w: normal);
}
.c_txt01{
  font-family: agehasprings;
  @include v_font_pc($f: 160, $h: 192, $l: 0, $w: normal);
  @include v_font_sp($f: 80, $h: 86, $l: 0, $w: normal);
}
.c_txt01_AA{
  @include v_font_vw_pc($f: 160, $h: 192, $l: 0, $w: normal);
  @include v_font_vw_sp($f: 80, $h: 88.3264, $l: 0, $w: normal);
}
.c_txt01_AB{ // 長いテキストなので少しフォント小さい
  // @include v_font_vw_pc($f: 160, $h: 192, $l: 0, $w: normal);
  @include v_font_vw_pc($f: 147, $h: 192, $l: 0, $w: normal);
  @include v_font_vw_sp($f: 61.25, $h: 67.625, $l: 0, $w: normal);
  @include v_mq(PC){
    line-height: 1.10;
  }
}
.c_txt01_AC{
  @include v_font_vw_pc($f: 160, $h: 192, $l: 0, $w: normal);
  @include v_font_vw_sp($f: 58.625, $h: 88.3264, $l: 0, $w: normal);
}
.c_txt01_01{
  opacity: .1;
  @include v_mq(SP){
    margin: 0 0 0 -16px;
  }
}
.c_txt01_02{
  margin: 0 0 0 -1.2vw;
  @include v_mq(SP){
    margin: 0;
  }
}
.c_txt02{
  font-family: agehasprings;
  @include v_font_vw_pc($f: 64, $h: 77, $l: 0, $w: normal);
  @include v_font_vw_sp($f: 40, $h: 60, $l: 0, $w: normal);
}
.c_txt02_01{
  // @include v_font_sp($f: 64, $h: 77, $l: 0, $w: normal);
  @include v_font_vw_sp($f: 62, $h: 77, $l: 0, $w: normal);
}
.c_txt03{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 24, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 24, $h: 28, $l: 50, $w: normal);
}
.c_txt03_01{
  position: relative;
  display: inline-block;
  &:after{
    content: '';
    width: 140px;
    background-image: url('../images/wanted.png');
    position: absolute;
    height: 85px;
    background-repeat: no-repeat;
    background-size: contain;
    left: calc(100% + 15px);
    top: 0;
    bottom: 20px;
    margin: auto;
  }
}
.c_txt04{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 18, $h: 28, $l: 50, $w: bold);
  @include v_font_sp($f: 18, $h: 28, $l: 50, $w: bold);
}
.c_txt05{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: normal);
}
.c_txt06{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: bold);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: bold);
}
.c_txt07{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 600);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 600);
}
.c_txt08{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
}
.c_txt09{
  @include v_font_pc($f: 12, $h: 28, $l: 50, $w: 600);
  @include v_font_sp($f: 12, $h: 28, $l: 50, $w: 600);
}
.c_txt10{
  @include v_font_pc($f: 12, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 12, $h: 28, $l: 50, $w: 300);
}
.c_txt11{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 16, $l: 50, $w: 400);
  @include v_font_sp($f: 14, $h: 16, $l: 50, $w: 400);
}
.c_txt12{
  @include v_font_pc($f: 24, $h: 36, $l: 50, $w: 600);
  @include v_font_sp($f: 24, $h: 36, $l: 50, $w: 600);
  color: white;
  &.is-clr{
    color: black;
  }
}
.c_txt13{
  // @include v_font_pc($f: 16, $h: 32, $l: 50, $w: normal);
  // @include v_font_sp($f: 16, $h: 28, $l: 50, $w: normal);
  @include v_font_pc($f: 18, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 18, $h: 28, $l: 50, $w: normal);

  margin: 0 0 24px;
}
.c_txt14{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 17, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 17, $l: 50, $w: 700);
  white-space: nowrap;
}
.c_txt15{
  @include v_font_pc($f: 12, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 12, $h: 28, $l: 50, $w: 300);
  &:hover{
    opacity: .6;
  }
}
.c_txt15_01{
  &:not(:last-child){
    @include v_mq(SP){
      margin-bottom: 10px;
    }
  }
}
.c_txt16{
  font-family: agehasprings;
  @include v_font_pc($f: 32, $h: 38, $l: 0, $w: 400);
  @include v_font_sp($f: 32, $h: 38, $l: 0, $w: 400);
  &:after{
    content: ' :';
  }
}
.c_txt17{
  font-family: 'agehasprings';
  @include v_font_pc($f: 64, $h: 77, $l: 0, $w: 400);
  @include v_font_sp($f: 40, $h: 60, $l: 0, $w: 400);
}
.c_txt17_white{
  color: white;
}
.c_txt18{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
}
.c_txt19{
  color: $clr_gray_80;
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 400);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 400);
}
