.c_width00{
  margin: 0 66px;
  @include v_mq(SP){
    margin: 0 36px;
  }
}
.c_width00_01{
  @include v_mq(SP){
    margin: 0;
  }
}
.c_width00_02{
  margin: 0 64px;
  @include v_mq(SP){
    margin: 0 0px;
  }
}
.c_width00_03{
  margin: 0 48px;
  @include v_mq(SP){
    margin: 0 36px;
  }
}
.c_width00_AA{
  margin: 0 16px;
  @include v_mq(SP){
    margin: 0;
  }
}
.c_width00__01{
  max-width: 1357px;
  margin: auto;
}
.c_width00__01_AA{
  max-width: initial;
}
.c_width00__01_AB{
  max-width: initial;
  margin: auto;
}
