.l_thumb01{
  margin: 0;
}
.c_thumb01{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
}
.c_thumb01__wrap01{
  margin: 0 15px 125px;
  width: calc(100% / 2 - 30px);

  &:first-child{
    width: 100%;
    & .c_thumb01__wrap02{
      padding-bottom: calc(384 / 1175 * 100%);
      @include v_mq(SP6){
        padding-bottom: 100%;
      }
    }

  }
  &:not(:first-child){
    display: flex;
    @include v_mq(SP6){
      flex-direction: column;
      width: 100%;
    }
    & .c_thumb01__wrap02{
      flex-shrink: 0;
      width: 50%;
      margin: 0 32px 0 0;
      padding-bottom: 275px;
      padding-bottom: 0;
      height: auto;
      height: 0;
      // padding-bottom: 100%;
      padding-bottom: 50%;
      @include v_mq(SP6){
        width: 100%;
        margin: 0 0 25px;
        padding-bottom: 100%;
      }
    }
    & .c_thumb01__img{
      height: auto;
    }
  }
  @include v_mq(SP){
    width: 100%;
    margin: 0 15px 48px;
  }
}
.c_thumb01__wrap02{
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  margin: 0 0 25px;
  position: relative;
}
.c_thumb01__wrap03{
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_thumb01__wrap04{
  &:not(:last-child){
    margin: 0 calc(40 / $g_design_size) * 100vw 0 0;
    @include v_mq(SP){
      margin: 0 0 16px;
    }
  }
}
.c_thumb01__wrap05{
  margin: 0;
}
.c_thumb01__img{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.c_thumb01__img_pc{
  display: initial;
  @include v_mq(SP6){
    display: none;
  }
}
.c_thumb01__img_sp{
  display: none;
  @include v_mq(SP6){
    display: initial;
  }
}
.c_thumb01_txt01{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 24, $h: 32, $l: 50, $w: normal);
  @include v_font_sp($f: 24, $h: 32, $l: 50, $w: normal);
  margin: 0 0 16px;
}
.c_thumb01_txt02{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: normal);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: normal);
}
$val_thumb_02: 67px;
.l_thumb02{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -1 * $val_thumb_02;
  @include v_mq(SP){
    width: 100%;
    margin: 0;
  }
}
.c_thumb02{
  margin: 0 $val_thumb_02 64px;
  width: calc(50% - $val_thumb_02 * 2);

  @include v_mq(SP){
    width: 100%;
    margin: 0 0 48px;
  }
}
.c_thumb02__link{
  display: block;
}
.c_thumb02__01{
  width: 100%;
  height: 0;
  padding-bottom: calc(270.15 / 470) * 100%;
  margin: 0 0 25px;
  position: relative;
  @include v_mq(SP){
    margin: 0 0 23px;
    padding-bottom: calc(204.34 / 304) * 100%;
  }
}
.c_thumb02__img{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.c_thumb02__txt{
  @include v_font_pc($f: 18, $h: 36, $l: 50, $w: 600);
  @include v_font_sp($f: 18, $h: 36, $l: 50, $w: 600);
  margin: 0 0 8px;
}
$val_thumb_03: 16px;
.l_thumb03{
  margin: 0 0 60px;
  @include v_mq(SP){
     margin: 0 -36px 80px;
  }
  & .slick-slide{
    @include v_mq(SP){
      margin: 0 16px;
    }
  }
  & .slick-list{
    @include v_mq(SP){
      padding: 0 32px !important;
    }
  }
  & .slick-track{
    @include v_mq(SP){
      margin: 0 -10px;
    }
  }
}
.l_thumb03__01{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 -1 * $val_thumb_03;
  @include v_mq(SP){
    width: 100%;
    margin: 0;
  }
}
.c_thumb03{
  margin: 0 $val_thumb_03 64px;
  width: calc(calc(100 / 3) * 1% - $val_thumb_03 * 2);

  @include v_mq(SP){
    width: 100%;
    margin: 0 0 0px;
  }
}
.c_thumb03__link{
  display: block;
}
.c_thumb03__01{
  width: 100%;
  height: 0;
  padding-bottom: calc(223 / 336) * 100%;
  margin: 0 0 16px;
  position: relative;
  @include v_mq(SP){
    margin: 0 0 16px;
    padding-bottom: calc(160 / 278) * 100%;
  }
}
.c_thumb03__img{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.c_thumb03__txt{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 600);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 600);
  margin: 0 0 8px;
}
.l_thumb04{
  margin: 0;
}
.c_thumb04{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
}
.c_thumb04__wrap01{
  margin: 0 0 125px;
  width: 100%;
  display: flex;

  @include v_mq(SP){
    width: 100%;
    margin: 0 0 48px;
    flex-direction: column;
  }
}
.c_thumb04__wrap02{
  margin: 0 32px 0 0;
  position: relative;

  // width: 370px;
  @include v_img_vw_pc(370);
  height: 370px;
  height: auto;
  flex-shrink: 0;
  @include v_mq(SP){
    margin: 0 0 40px;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
}
.c_thumb04__wrap03{
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
  }
}
.c_thumb04__wrap04{
  margin: 0;
}
.c_thumb04__wrap05{
  padding-bottom: 100%;
  position: relative;
}
.c_thumb04__img{
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}
.c_thumb04__img_pc{
  display: initial;
  @include v_mq(SP){
    display: none;
  }
}
.c_thumb04__img_sp{
  display: none;
  @include v_mq(SP){
    display: initial;
  }
}
.c_thumb04_txt01{
  font-family: 'aktiv-grotesk';
  @include v_font_pc($f: 32, $h: 56, $l: 50, $w: 400);
  @include v_font_sp($f: 32, $h: 56, $l: 50, $w: 400);
  margin: 0 0 16px;
}
.c_thumb04_txt02{
  @include v_font_pc($f: 14, $h: 28, $l: 50, $w: 300);
  @include v_font_sp($f: 14, $h: 28, $l: 50, $w: 300);
}
.c_thumb04_txt03{
  @include v_font_pc($f: 14, $h: 16, $l: 50, $w: 700);
  @include v_font_sp($f: 14, $h: 16, $l: 50, $w: 700);
}
