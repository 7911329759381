.l_col{
  display: flex;
  @include v_mq(SP){
    flex-direction: column;
    padding: 0 $g_val_02;
  }
}
.l_col_rightOverflow{
  overflow: hidden;
}
$val_01: $g_val_03;
.l_col__left{
  @include v_img_vw_pc($w: $val_01);
  flex-shrink: 0;
  @include v_mq(SP){
    width: 0;
  }
}
.l_col__right{
  width: calc(100% - calc($val_01 / $g_design_size) * 100vw);
  @include v_mq(SP){
    width: 100%;
  }
}
.l_col__right_rightOverflow{
  width: 100%;
}
.l_col__spaceRight{
  margin: 0 calc(131 / $g_design_size) * 100vw 0 0;
  @include v_mq(SP){
    margin: 0;
  }
}
.l_col__spaceRight_01{
  margin: 0 6.303977vw 0 0;
  @include v_mq(SP){
    margin: 0;
  }
}
.l_col__spaceRight_02{
  margin: 0 33px 0 0;
  @include v_mq(SP){
    margin: 0;
  }
}
////////////////////////////////////
.l_mv{
  margin: 0;
}
.l_mv__01{
  position: fixed;
  margin: 26vh 0 0;
  @include v_mq(SP){
    top: 230px;
    margin: 0;
  }
}
.l_mv__01_notFixed{
  position: absolute;
  bottom: 43px;
  left: $g_val_01 * 1px;
  @include v_mq(SP){
    top: auto;
    left: 47px;
  }
}
.l_mv__Edge{
  position: fixed;
  background: $clr_back_01;
  // background: red;
  $val: 16px;
  &:nth-child(1){
    top: 0;
    left: 0;
    width: 100%;
    height: $val;
  }
  &:nth-child(2){
    top: 0;
    left: 0;
    width: $val;
    height: 100vh;
  }
  &:nth-child(3){
    top: 0;
    right: 0;
    width: $val;
    height: 100vh;
  }

}
.l_back01{
  background: $clr_back_01;
}
.l_back01_business{
  background: $clr_black_00;
  position: relative;
  &:before{
    content: '';
    background-image: url('../images/parts_bussiness_pc.jpg');
    @include v_mq(SP){
      background-image: url('../images/parts_bussiness_sp.jpg');
    }
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    z-index: -1;

    opacity: .2;
    opacity: 1;

    background-attachment: fixed;
    background-position: bottom;
    @include v_mq(SP){
      // top: 100vh;
      top: 0;
    }
  }
  &.is-backfixedCurrent{
    &:before{
      @include v_mq(SP){
        background-attachment: scroll;
        position: fixed;
        will-change: top;
        // background-position: top;
        // transition: top .1s;
      }
    }
  }
  &.is-backfixedEnd{
    @include v_mq(SP){
      position: static;
    }
  }
}

.l_back01_01{
  position: relative;
  z-index: 1;
}
.l_back01_02{
  @include v_mq(SP){
    z-index: 2;
  }
}
.l_back01_AA{
  padding: 160px 0 0;
  @include v_mq(SP){
    padding: 200px 0 0;
  }
}
.l_back01_AB{
  padding: 353px 0 0;
  padding: 333px 0 0;
  padding: 313px 0 0;
  @include v_mq(SP){
    padding: 159px 0 0;
  }
}
.l_back01_AC{
  padding: 85px 0 0;
  @include v_mq(SP){
    padding: 40px 0 0;
  }
}
.l_back01_AD{
  padding: 147px 0 0;
  padding: 100vh 0 0;
  @include v_mq(SP){
    padding: 39px 0 0;
    padding: 100vh 0 0;
  }
}
.l_back01_AE{
  padding: 68px 0 0;
  // padding: 0;
  padding: 1000px 0 0;
  @include v_mq(SP){
    padding: 500px 0 0;
  }
}
.l_colFooter{
  display: flex;
  @include v_mq(footer_SP){
    flex-direction: column;
  }
}
.l_colFooter_01{
  // padding: 300px 0 0;
  padding: 81px 0 0;
  @include v_mq(SP){
    // padding: 240px 0 0;
    padding: 81px 0 0;
  }
}
.l_colFooter_02{
  padding: 86px 0 0;
  @include v_mq(SP){
    padding: 39px 0 0;
  }
}
.l_colFooter_02_contactPage{
  padding: 0px 0 0;
  @include v_mq(SP){
    padding: 0px 0 0;
  }
}
.l_colFooter_03{
  @include v_mq(footer_SP){
    margin: 0 0 50px;
  }
}
.l_colFooter_04{
  @include v_mq(footer_SP){
    margin: 30px 0 0;
  }
}
$val_02: 470px;
.l_colFooter__colLeft{
  width: $val_02;
  padding: 0 135px 0 0;
  @include v_mq(footer_SP){
    padding: 0;
    width: 100%;
  }
}
.l_colFooter__colRight{
  width: calc(100% - $val_02);
  @include v_mq(footer_SP){
    width: 100%;
  }
}
.l_footer{
  padding: 0 0 40px;
  background: $clr_black_00;

  position: relative;
  z-index: 1;

  color: white;
}
.l_footer_fixed{
  position: fixed;
  bottom: 0;
  width: 100%;


  height: 100vh;
  z-index: -100;
  &.is-current{
    z-index: 0;
  }
  // pointer-events: none;
  &.is-footerScrolled{
    overflow-y: scroll;
    // pointer-events: all;
    z-index: 1; // メインコンテンツがフッタの上に数px（ページごとにまちまちであり原因はわかってない）かぶさることへの対応。
  }
}
.l_footer_contact{
  padding-top: 320px;
  @include v_mq(SP){
    padding-top: 160px;
  }
}
.l_footer__01{
  display: none; // 初期状態
  padding: 32px;
  background: rgba(255,255,255,.1);
}
.l_footer__02{
  border-top: 1px solid $clr_gray_E8;
  margin: 32px 0 0;
  padding: 16px 0 0;
}
.l_footer__03{
  margin: 0 0 56px;
}
.l_footer__04{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include v_mq(SP){
    align-items: flex-start;
  }
}
.l_footer__05{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include v_mq(SP){
    align-items: flex-start;
  }
}
.l_footer__06{
  @include v_mq(SP){
    margin: 0 0 32px;
  }
}
.l_footer__07{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  @include v_mq(SP){
    flex-direction: column;
    align-items: flex-start;
  }
}
.l_footer__back01{
  // background-image: url('../images/footer_back.png');
  background-image: url('../images/footer_back.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 80%);
  }
}
.l_work{
  margin: 0;
}
.l_work__01{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 97px;
  @include v_mq(SP1){
    flex-direction: column;
    align-items: flex-start;
  }
  @include v_mq(SP){
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 76px;
  }
}
.l_work__02{
  margin: 0;
  @include v_mq(SP1){
    margin: 0 0 30px;
    width: calc(100% + $g_val_02);
  }
}
.l_comp15{
  margin: 0 0 78px;
  @include v_mq(SP){
    margin: 0 0 50px;
  }
}
.l_comp17{
  display: flex;
  justify-content: center;
}
.l_singleWork{
  margin: 0;
}
.l_singleWork__01{

}
.l_singleWork__02{
  margin: -4.5vw 0 0 245px;
  @include v_mq(SP){
    margin: -10vw 0px 0;
  }
}
.l_moreInfo{
  display: flex;
  align-items: flex-start;
}
.l_moreInfo__01{
  display: flex;
  flex-wrap: wrap;
  @include v_mq(SP){
    flex-direction: column;
  }
}
.l_share{
  display: flex;
  flex-shrink: 0;
}
.l_share__01{
  display: flex;
}
.l_share__02{
  &:not(:last-child){
    margin: 0 40px 0 0;
  }

  &:nth-child(1){
    width: 18px;
  }
  &:nth-child(2){
    width: 22px;
  }
  &:nth-child(3){
    width: 18px;
  }
  &:nth-child(4){
    width: 18px;
  }
  &:nth-child(5){
    width: 19.5px;
  }
}
.l_share__02_01{
  &:not(:last-child){
    margin: 0 28px 0 0;
  }
}
.l_luminance01{
  margin: 0;
  position: relative;
}
.l_darken{
  // position: relative;
  // &:before{
  //   content: '';
  //   background: transparent;
  //   opacity: .2;
  //   opacity: .8;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 2;
  //
  //   transition: .3s;
  // }
  // &.is-current{
  //   &:before{
  //     background: black;
  //   }
  // }
}
.l_darken__dark{
  position: relative;
  &:after{
    content: '';
    background: transparent;
    opacity: .2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    transition: .3s;
  }
  &.is-current{
    &:after{
      background: black;
    }
  }
}
.l_darken__dark_01{
  &:after{
    opacity: .5;
  }
}
.l_darken__dark_02{
  &:after{
    opacity: .0;
  }
}
.l_darken__surface{
  position: relative;
  z-index: 1;
}
.l_comp25{
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: opacity .3s;

  pointer-events: none;

  opacity: 0;// 初期状態
  &.is-current{
    opacity: 1;
  }
  &.is-end{
    position: absolute;
    bottom: 0;
    top: auto;
    overflow: hidden;
  }
}
