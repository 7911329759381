$v_bps: (
  'SP': 'only screen and (max-width: 768px)',
  'PC': 'all and (min-width: 769px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'SP1': 'only screen and (max-width: 1000px)',
  'PC1': 'all and (min-width: 1001px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'SP2': 'only screen and (max-width: 850px)',
  'PC2': 'all and (min-width: 851px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'SP3': 'only screen and (max-width: 930px)',
  'PC3': 'all and (min-width: 931px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  // 'SP4': 'only screen and (max-width: 1300px)',
  'SP4': 'only screen and (max-width: 768px)',
  // 'PC4': 'all and (min-width: 1301px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'PC4': 'all and (min-width: 769px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'SP5_1': 'only screen and (max-width: 1440px)',
  'PC5_1': 'all and (min-width: 1441px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'SP5_2': 'only screen and (max-width: 1200px)',
  'PC5_2': 'all and (min-width: 1201px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'SP5_3': 'only screen and (max-width: 980px)',
  'PC5_3': 'all and (min-width: 981px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'SP6': 'only screen and (max-width: 980px)',
  'PC6': 'all and (min-width: 981px)', // PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'header_SP': 'only screen and (max-width: 1170px)',
  'header_PC': 'all and (min-width: 1171px)',// PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  'footer_SP': 'only screen and (max-width: 1180px)',
  'footer_PC': 'all and (min-width: 1181px)',// PC版のスタイルが印刷プレビューに効かない可能性があるので、PC版のメディアクエリはなるべく使用しないこと。
  ////////////////////////////////
) !default;
@mixin v_mq($bp) {
  @media #{map-get($v_bps, $bp)} {
    @content;
  }
}
@mixin v_font_pc($f, $h, $l, $w) {
  @include v_mq(PC){
    font-size: $f * 1px;
    line-height: calc($h / $f);
    letter-spacing: calc($l / 1000) * 1em;
    font-weight: $w;
  }
}
$g_design_size: 1408;
$g_sp_design_size: 375;
@mixin v_font_vw_pc($f, $h, $l, $w) {
  @include v_mq(PC){
    font-size: ($f / $g_design_size) * 100vw;
    // font-size: ($f / ($g_design_size + 13)) * 100vw;
    line-height: calc($h / $f);
    letter-spacing: calc($l / 1000) * 1em;
    font-weight: $w;
  }
}
@mixin v_font_sp($f, $h, $l, $w) {
  $rate: 1;
  @include v_mq(SP){
    font-size: calc($f / $rate) * 1px;
    line-height: calc(calc($h / $rate) / calc($f / $rate));
    letter-spacing: calc($l / 1000) * 1em;
    font-weight: $w;
  }
}
@mixin v_font_vw_sp($f, $h, $l, $w) {
  @include v_mq(SP){
    font-size: ($f / $g_sp_design_size) * 100vw;
    line-height: calc($h / $f);
    letter-spacing: calc($l / 1000) * 1em;
    font-weight: $w;
  }
}
@mixin v_img_pc($w) {
  @include v_mq(PC){
    width: $w * 1px;
  }
}
@mixin v_img_vw_pc($w) {
  @include v_mq(PC){
    width: calc($w / $g_design_size) * 100vw;
  }
}
@mixin m_excerpt($height: auto, $line: 2) {
  height: $height;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}
//////////////
$g_val_01: 64;
$g_val_02: 36px;
$g_val_03: 233;
//////////////
$clr_back_01: #E9E9E9;
$clr_white_01: rgba(255, 255, 255, 0.3);
$clr_white_02: rgba(255, 255, 255, 0.6);
$clr_white_03: rgba(255, 255, 255, 0.5);
$clr_black_00: #000000;
$clr_gray_80: #808080;
$clr_gray_C0: #C0C0C0;
$clr_gray_E8: #E8E8E8;
$clr_gray_50: #505050;
$clr_gray_5A: #5A5A5A;
$clr_gray_28: #282828;
$clr_gray_AB: rgba(128, 128, 128, .3);
$clr_green_01: #00FFD1;
$clr_green_02: #02F6BC;
$clr_red_01: #FF0000;
$clr_red_02: #F00000;
