.c_logo01{
  position: fixed;
  z-index: 10000;

  // top: calc($g_val_01 / $g_design_size) * 100vw;
  // left: calc(65.86 / $g_design_size) * 100vw;
  top: $g_val_01 * 1px;
  left: $g_val_01 * 1px;


  @include v_mq(SP){
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 30px;
    // top: 56px;
  }
  & svg{
    @include v_img_vw_pc($w: 120);
    height: auto;
    @include v_mq(SP){
      width: 80px;
    }

  }
  & path{
    transition: .3s;
  }
}
.c_logo01_fixed{
  transition: .3s;
  &:hover{
    opacity: .5;
  }
  & svg{
    cursor: pointer;
    transition: .3s;
  }
  @mixin logoSize {
    transform: scale(calc(96 / 120));
    @include v_mq(PC){
      transform-origin: top left;
    }
  }
  &.is-topPage{
    & svg{
      @include v_mq(SP){
        margin: 26px 0 0;
      }
      &.is-scrolled{
        @include logoSize;
        @include v_mq(SP){
          margin: 0px 0 0;
        }
        & .c_logo01__companyName{
          @include v_mq(SP){
            opacity: 0;
          }
        }
      }
    }
  }
  &.is-btmPage{
    @include logoSize;
    & .c_logo01__companyName{
      @include v_mq(SP){
        opacity: 0;
      }

    }
  }
  &.is-contactPage{
    position: absolute;
    & .c_logo01__01{
      opacity: 1;
    }
  }
}
.c_logo01_fullDispMenu{
  & .c_logo01__companyName{
    @include v_mq(SP){
      display: none;
    }
  }

}
.c_logo01__01{
  transition: .3s;
  &.is-footerCurrent{
    opacity: 0;
    pointer-events: none;
    // @include v_mq(SP){
    //   opacity: 1;
    //   pointer-events: all;
    // }
  }
}
.c_logo01__companyName{
  @include v_mq(SP){
    opacity: 1;
    transition: .3s;
  }

}
