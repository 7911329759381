@mixin m_arwCommon01($w: 10px, $h: 10px, $clr: red) {
  content: '';
  position: absolute;
  width: $w;
  height: $h;
  border-top: 1px solid $clr;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}
.u_arwRight01{
  position: relative;
  &:before{
    @include m_arwCommon01(10px, 10px, #525665);
    transform: rotate(45deg);
  }
  &:after{
    @include m_arwCommon01(10px, 10px, #525665);
    transform: rotate(135deg);
  }
}
